import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
// import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { FaBars,FaDiscord,FaTwitter } from "react-icons/fa";
import {ReactComponent as Opensea} from '../icons/Opensea.svg'
// import {useWallet} from '../ethWallet/useWallet'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const DropDownMenu = ({totalSupply,listings}) => {
  // const wallet = useWallet()
  return (
    <Menu as="div" className="text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 "
        >
        <FaBars className="fa fa-3x" style={{color:"white"}} size='22px' />
          {/* <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
    
        <Menu.Items className="absolute left-10 z-40 mt-2 w-56 origin-top-left focus:outline-none text-white bg-black">
          <div className="py-1">

          <Menu.Item>
                <a href="/"
                className={classNames('block px-4 py-2 text-sm')}>
                 Home
                </a>
            </Menu.Item>

            {/* <Menu.Item>
                <a
                  href="https://theburning.onchainscores.xyz/"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                BURNING
                </a>
            </Menu.Item> */}


            <Menu.Item>
                <a href="/check-sp"
                className={classNames('block px-4 py-2 text-sm')}>
                 Checker
                </a>
            </Menu.Item>

            <Menu.Item>
                <a
                  href="/weekly-leaderboard"
                  className={classNames('block px-4 py-2 text-sm')}>
                  Weekly Leaderboard
                </a>
            </Menu.Item>

            {/* <Menu.Item>
            <a  href="/playtest"
                className={classNames('block px-4 py-2 text-sm')}>
                  playtest
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="/sbt-leaderboard"
                  className={classNames('block px-4 py-2 text-sm')}>
               points Leaderboard
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="/playtest-leaderboard"
                  className={classNames('block px-4 py-2 text-sm')}>
               Playtest Leaderboard
                </a>
            </Menu.Item> */}
      {/*      
            <Menu.Item>
                <a
                  href="https://offchain-leaderboard.onchainscores.xyz/"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                  playtest Leaderboard
                </a>
            </Menu.Item>
          */}
           
      
            {/* <Menu.Item>
                <a href="/info" className={classNames('block px-4 py-2 text-sm')}>
                 Info
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSehTmwqEA41aQnzhaGGUBaLCKPpFax2eYtMAO0YAY23LBpXVA/viewform?usp=sf_link"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
              Help
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="https://opensea.io/collection/onchainpoints"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                <Opensea fill="#ffffff" />
                </a>
            </Menu.Item> */}

            <Menu.Item>
                <a
                  href="https://twitter.com/onchainscores"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                <FaTwitter className="fa fa-3x" size='18px' />
                </a>
            </Menu.Item>
            {/* <Menu.Item>
                <a href="/home" className={classNames('block px-4 py-2 text-sm' )}>
                  Home
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="/howto" className={classNames('block px-4 py-2 text-sm' )}>
                  How to
                </a>
            </Menu.Item> */}

      
            {/* <Menu.Item>
                <a href="/faq" className={classNames('block px-4 py-2 text-sm')}>
                 FAQ
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="https://testnets.opensea.io/collection/scores-25"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                <Opensea fill="#ffffff" />
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="https://twitter.com/onchainscores"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                <FaTwitter className="fa fa-3x" size='18px' />
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="https://discord.gg/K2dWftQtPy"
                  className={classNames( 'block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                 <FaDiscord className="fa fa-3x" size='18px' />
                </a>
            </Menu.Item> */}

            {/* <Menu.Item>
                <a
                  href="/"
                  className={classNames('block px-4 py-2 text-sm')}>
                  on sale : {listings}
                </a>
            </Menu.Item> 

            <Menu.Item>
                <a
                  href="/"
                  className={classNames('block px-4 py-2 text-sm')}>
                   total supply : {totalSupply}
                </a>
            </Menu.Item>  */}
           
          </div>
        </Menu.Items>
      
      </Transition>
    </Menu>
  )
}