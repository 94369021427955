import PropTypes from "prop-types";

function Page({ children }) {
  return (
    <div className="pt-8">
      {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page;
