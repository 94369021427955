import Header from "./app/Header";
import Footer from "./app/Footer";
import Routes from "./Routes";
import {isMobile} from './utils/isMobile'


function Main () {

  return (
    <div className='flex flex-col bg-offblack font-mono text-white min-h-screen max-h-full max-w-full'>
      <Header />
      <Routes />
      {isMobile() || <Footer />}
    </div>
  )
}

export default Main
