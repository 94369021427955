import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";
import { forwardRef } from "react";

const Link = forwardRef(function _Link({ border, children, className: classNameProp, ...props }, ref) {
  let className = "fm-link-button";

  if (border) {
    className = `${className} border-b-2 border-black`;
  }

  if (classNameProp) {
    className = `${className} ${classNameProp}`;
  }

  return (
    <ReactRouterLink ref={ref} className={className} {...props}>
      {children}
    </ReactRouterLink>
  );
});

Link.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  border: true,
  className: null,
};

export default Link;
