import React, {useEffect, useState} from 'react'
// import { RandomCube, CubeWrapper,CubeMainWrapperHomePage,CubeMainWrapperCombinations ,CubeMainWrapper} from '../components/CubeComponents'
import { SvgWrapper} from '../components/SvgComponents'
import {isMobile} from '../utils/isMobile'
import mintPowerImg from "../img/mintPowerImg.png";



const InfoPage = () => {

  const colorID = ['#ffffff', '#ffcd00', '#df2020', '#4cb2e6', '#ff7b00', '#5ED723']
 
  return(
    <>
   <div className='flex flex-grow flex-col justify-center text-left overflow-hidden' >
   <div style={{marginRight:isMobile()? "10%" : "25%",marginLeft:isMobile()? "10%": "25%",marginTop:"2%",marginBottom:"2%"}} >
        <h1 className=" text-xl text-white my-6 custome-font"> 
        What is onchainscores? 
        </h1>
        Scores is a 100% on-chain NFT game. It is a proof of concept creating a peer to peer on-chain scoring system thanks to blockchain technology, smart contracts and dynamic NFTs. <br />
        Scores is going to come to life as part of a wider ecosystem, after different stages where new systems are created to allow the formation of the project with the community. <br/>
        For now we are not going to focus on the game mechanics, which will be revealed later, but on the creation of the different protocols leading to the minting of the scores NFTs.
        <h1 className=" text-xl text-white my-6 custome-font"> 
        The journey to the mint 
        </h1>
        We have introduced the Twitter mining protocol where members of the community are able to farm tokens with their interactions. <br />
        These tokens are necessary for the game and fueling the ecosystem. Certain tokens will allow participants to create new and different tokens thanks to burn to redeem mechanics. The currently known tokens are POINTS, MINT POWER and SPEED BOOST.
        <h1 className=" text-xl text-white my-6 custome-font"> 
        Points
        </h1>
        <p className='my-4'>
        The first protocol introduced is POINTS which are tokenized web2 actions. <br />
        Points are the allowlist token of the on-chain scores ecosystem. <br />
        </p>
        <p className='my-4'>
        Points have been accumulated from participating in the Twitter mining protocol. <br />
        All the members of the community participating in the hourly social media tasks accumulated points that have later been tokenized. 
        </p>
        This concept was introduced to be accessible and meritocratic and give opportunities to enter the game with no capital power. Simply by participating and paying attention members of the community had the opportunity to access the project and possibly minting the tokens. These tokenized actions are now tradable in common marketplaces and provide digital ownership of their points to the participants.
        <p className='my-4'>
        The number of Points participants were allowed to mint was determined by the number of interactions with the protocol. 
        </p>
        Points are ERC-1155 tokens, semi-fungible tokens and also allows batch transfers, which can reduce transaction costs and times, but also tradable on the common NFT platforms. This allowed participants to tokenize and receive many points accumulated with a single low cost gas blockchain transaction. 
        <p className='my-4'>
        Points are going to be burned to create Mint Power Tokens. <br />
        </p>
        <p className='my-4'>
       
        Points OS PRO link:
        {isMobile() ? <br /> :""}
        <u><a className='ml-3' href="https://pro.opensea.io/collection/onchainpoints" target="_blank" rel="noopener noreferrer">
          https://pro.opensea.io/collection/onchainpoints
        </a></u>
        </p>

        <h1 className=" text-xl text-white my-6 custome-font"> 
        Mint Power Tokens (MPT)
        </h1>
        <p className='my-4'>
        Mint Power Tokens can be created by burning Points tokens during specific burning windows. 
        </p>
        MPT will be ERC-721 tokens and how much mint power each token will have is based on the quantity of point burned and which burning window.
        <p className='my-4'>
        These are the burning mechanics quantities.
        </p>

        <div className='flex flex-row my-6 justify-center text-center'
        style={{marginRight: isMobile()? "15%" : "30%",marginLeft:isMobile()? "15%" :"30%"}} >
          <img src={mintPowerImg} className="cursor-pointer mx-6 mt-6" />
        </div>


        <p className='my-4'>
        Each burning window will come with a bonus multiplier that will increase the mint power of the newly created token.
        </p>
        <p className='my-4'>
        There will be 3 burning windows.
        </p>
        <ul role="list" class="marker:text-sky-400 list-disc pl-5 space-y-3 text-slate-500">
        <li><p className='my-4'>
        The first will give 3x mint power. <br />
        I.e. burning 100 points gives 1 NFT with mint power 1, by burning in the first window with the multiplier will give an NFT with mint power 3. 
        </p></li>
        <li><p className='my-4'>
        The second will give 2x mint power.
        </p></li>
        <li><p className='my-4'>
        The third will give 1x mint power.
        </p></li>
        </ul>
        <h1 className=" text-xl text-white my-6 custome-font"> 
        Speed Boost Token (SBT)
        </h1>
        Speed boost token allows you access to multipliers windows



     
       <div className='flex  flex-row my-6 justify-center text-center' >
      {colorID.map((color,i) => {
       return [
         <p key={color} className='p-4' style={{backgroundColor: color,margin:"1px"}}></p>
       ]}).flat()}
      </div>
   </div>
 </div>
 </>
 )
}
export default InfoPage
