/* eslint-disable react/prop-types, no-void, jsx-quotes, quotes */

import React, {useEffect} from 'react'
import {useToast} from '../hooks/useToast'
// import {Corelib} from '../adapter/improxy-red'
import {isMobile} from "../utils/isMobile";
import { FaTruckMonster } from 'react-icons/fa';
import SVG from 'react-inlinesvg';

// const {getRnd} = Corelib

const svgHead = '\
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 1120 500">\
  <style>\
    .c0 {fill: url(#c0);}\
    .c1 {fill: url(#c1);}\
    .c2 {fill: url(#c2);}\
    .c3 {fill: url(#c3);}\
    .c4 {fill: url(#c4);}\
    .c5 {fill: url(#c5);}\
    .c6 {fill: url(#c6);}\
    text {\
      font-family:Roboto-Regular,Roboto;\
     }\
  </style>\
  <defs>\
  <linearGradient id="c0" x1="0" x2="1" y1="0" y2="1">\
  <stop stop-color="hsl(55,75%,100%)" offset="10%"/>\n\
  <stop stop-color="hsl(55,75%,100%)" offset="50%"/>\n\
  <stop stop-color="hsl(55,75%,100%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c1" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(55,75%,60%)" offset="10%"/>\n\
    <stop stop-color="hsl(55,75%,50%)" offset="50%"/>\n\
    <stop stop-color="hsl(55,75%,40%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c2" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(0,75%,50%)" offset="10%"/>\n\
    <stop stop-color="hsl(0,75%,40%)" offset="50%"/>\n\
    <stop stop-color="hsl(0,75%,30%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c3" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(200,75%,60%)" offset="10%"/>\n\
    <stop stop-color="hsl(200,75%,50%)" offset="50%"/>\n\
    <stop stop-color="hsl(200,75%,40%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c4" x1="0" x2="1" y1="0" y2="1">\
  <stop stop-color="hsl(25,85%,60%)" offset="10%"/>\n\
  <stop stop-color="hsl(25,85%,50%)" offset="50%"/>\n\
  <stop stop-color="hsl(25,85%,40%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c5" x1="0" x2="1" y1="0" y2="1">\
  <stop stop-color="hsl(140,75%,50%)" offset="10%"/>\n\
  <stop stop-color="hsl(140,75%,40%)" offset="50%"/>\n\
  <stop stop-color="hsl(140,75%,30%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c6" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(0,0%,30%)" offset="10%"/>\n\
    <stop stop-color="hsl(0,0%,20%)" offset="50%"/>\n\
    <stop stop-color="hsl(0,0%,10%)" offset="90%"/>\n\
  </linearGradient>\
  </defs>';

  const zoom = 1;
  const facewi = 160 * zoom;
  const facehi = 168 * zoom;

  const dimsX = [30, 210, 390, 570, 750, 930];
  const dimsY = [160, 160, 160, 160, 160, 160];


  const shuffleArray = ( array, length) => {
    let newArray=[];
    let k = 0;
    for (let i = length ; i > 0; i--) {
      newArray[k] = array[i-1];
      k++;
    }
    return newArray;
  }

  function squareCount2faces( col,  squareCount, isSimulator) {
    let j = isSimulator ? 1 : 3
    let max = (col + j) * (col + j)
    let shuffled = [];

    for (let i = 0; i < max; i++ ) {
      shuffled[i] = i < squareCount ? 1 : 0;
    }
   return shuffleArray(shuffled, max);
  }

  function drawFace( i,  faces, isSimulator) {
    
    let side = isSimulator ? ( i + 1 ) : ( i + 3);
    let fawi = (facewi + (side / 2)) / side;
    let wihi = (fawi - zoom).toString();
    let xy = [(dimsX[i] - 4) * zoom, (dimsY[i] - 4) * zoom];
    let faceCnt = 0;
   let out = '';
    for (let yy = 0; yy < side; yy++) {
      let y = xy[1] + yy * fawi;
      for (let xx = 0; xx < side; xx++) {
        let x = xy[0] + xx * fawi;
        let classs = faces[faceCnt++] > 0 ? i : 6;
        out = 
          out+
          '<rect class="c'+ classs.toString()+ '" x="'+ x.toString()+'" y="'+ y.toString()
          + '" width="' + wihi + '" height="' + wihi + '"></rect>'
          // '<circle r='+ ((fawi - zoom)/2) +' class="c'+ classs.toString()+ '" cx="'+ (x +(wihi/2)).toString()+'" cy="'+ (y+(wihi/2)).toString()
          // + '" ></circle>'
      }
    }
    
    return out;
  }
  const drawFrag = (squareCount,isSimulator) => {
    let facesArr=[];
    let svgs=[];
    
   
    for (let i = 0; i < 6; i++ ) {
      facesArr[i] = squareCount2faces(i, squareCount[i], isSimulator);
      svgs[i] = drawFace(i, facesArr[i],isSimulator);
    }
  
   return svgs[0]+ svgs[1]+svgs[2]+ svgs[3]+ svgs[4]+ svgs[5];
  }

  const genSVG = (squareCount,id,score,isSimulator) => {
   let y = 430;
   let max = isSimulator ? [1,4,9,16,25,36] : [9,16,25,36,49,64];
    return svgHead +
    '<rect x="0" y="0" width="1120" height="760" style="fill:#00000080"/>'+
     '<text x="30" y="90" font-size="3.4em" class="c0" > Block : '+ id +' </text>' +
     '<text x="786" y="90" font-size="3.4em" class="c0" > Score : '+ score +' </text>' +
      drawFrag(squareCount,isSimulator)+
      '<text x="50" y="'+y+'" font-size="3.4em" class="c0" >'+squareCount[0].toString()+'/'+ max[0] +'</text>' +
      '<text x="210" y="'+y+'" font-size="3.4em" class="c0" >'+squareCount[1].toString()+'</text><text x="265" y="'+y+'" font-size="3.4em" class="c1" >/'+ max[1] +'</text>' +
      '<text x="400" y="'+y+'" font-size="3.4em" class="c0" >'+squareCount[2].toString()+'</text><text x="453" y="'+y+'" font-size="3.4em" class="c2" >/'+ max[2] +'</text>' +
      '<text x="590" y="'+y+'" font-size="3.4em" class="c0" >'+squareCount[3].toString()+'</text><text x="650" y="'+y+'" font-size="3.4em" class="c3" >/'+ max[3] +' </text>' +
      '<text x="785" y="'+y+'" font-size="3.4em" class="c0" >'+squareCount[4].toString()+'</text><text x="845" y="'+y+'" font-size="3.4em" class="c4" >/'+ max[4] +' </text>' +
      '<text x="962" y="'+y+'" font-size="3.4em" class="c0" >'+squareCount[5].toString()+'</text><text x="1020" y="'+y+'" font-size="3.4em" class="c5" >/'+ max[5] +' </text>' +
      '</svg>'
    ;
  }


export const SvgWrapper = ({props,id,score,isSimulator=false}) =>

<div className="flex text-center justify-center">
<SVG src={genSVG(props,id,score,isSimulator)} width={"95%"} />
</div>

export const SvgMainWrapper = ({props}) =>

<div className="flex flex-grow text-center justify-center">
<SVG src={genSVG(props)} width={"80%"}/>
</div>
 

export const CubeWrapper = ({children}) => 
  <div className="_CubeWrapper flex flex-grow flex-col overflow-hidden">
    <div className="_CubeChildrenWrapper w-full h-full relative ">
      {children}
    </div>
  </div>
