/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, jsx-a11y/click-events-have-key-events,
   jsx-a11y/no-static-element-interactions, react/no-array-index-key
*/

import {useState} from 'react'

export const MxConfirm = ({lines, onCancel, onConfirm, children}) => (
  <div className='mxdia mxcent confirm'>
    <div className='close-icon' onClick={onCancel} />
    {lines.map((line, ix) => <div className='line' key={ix}>{line}</div>)}
    {children}
    <div className='cmd big-center' onClick={onConfirm}>Confirm</div>
  </div>
)

const {sessionStorage: session} = window

export const ExperiBar = ({children}) => {
  const [visible, setVisibility] = useState(!session.getItem('iknowthisisexperimental'))

  const hide = () => {
    setVisibility(false)
    session.setItem('iknowthisisexperimental', true)
  }
  return null;

  // return visible
  //   ? <div className='mxdia mxhead experibar'>
  //       <div className='close-icon' onClick={hide} />
  //       Fragments NFT <a href="https://github.com/WhiteHatDAO/Smart-Contract-Security-Audits-by-White-Hat-DAO/blob/main/Frangments%20NFT%20Audit%20Report.pdf" target="_audit" style={{textDecoration: 'underline'}}>was audited</a>. However, it is still an experimental software. Please use at your own risk.
  //       {children}
  //     </div>
  //   : null  
}