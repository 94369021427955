import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const ToastStateContext = createContext({ toasts: [] });
const ToastDispatchContext = createContext(null);

function ToastReducer(state, action) {
  switch (action.type) {
    case "ADD_TOAST": {
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    }
    case "DELETE_TOAST": {
      const updatedToasts = state.toasts.filter((e) => e.id !== action.id);
      return {
        ...state,
        toasts: updatedToasts,
      };
    }
    default: {
      throw new Error("Unknown Toast action");
    }
  }
}

export const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ToastReducer, {
    toasts: [],
  });
  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        {children}
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useToastDispatchContext = () => useContext(ToastDispatchContext);
export const useToastStateContext = () => useContext(ToastStateContext);