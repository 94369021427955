import React, {useEffect, useState} from 'react'
import {isMobile} from '../utils/isMobile'
import axios from 'axios'
import {useToast} from '../hooks'

// Add Variables to .env file
//REACT_APP_GRAPHCMS_API_URL
//REACT_APP_GRAPHCMS_ADMIN_KEY

const HYGRAPH_API_URL = process.env.REACT_APP_HASURA_ENDPOINT;
const authkey = process.env.REACT_APP_HASURA_SECRET_KEY;

const query = `
  query GetPointsPhaseTwo($wallet: String!) {
    points_phase_two(where: {wallet: {_ilike: $wallet}}) {
      wallet
      points
    }
  }
`;

const queryLatestUpdate = `
  query GetLatestEntry {
    points_phase_two(order_by: {created_at: desc}, limit: 1, offset: 0) {
      created_at
    }
  }`;


  const GET_WEEKLY_POINTS_PER_USER = `
  query GetWeeklyLeaderboard($address: String!)  {
    weekly_points(where: {address: {_ilike: $address}}) {
      address
      points
    }
  }
`;

  const GET_WEEKLY_HIGHEST_POINTS = `
  query GetWeeklyLeaderboard {
    weekly_points(order_by: {points: desc}) {
      address
      points
    }
  }
`;

const headers = {
  'Content-Type': 'application/json',
  'x-hasura-admin-secret': authkey,
};

const colorID = ['#ffffff', '#ffcd00', '#df2020', '#4cb2e6', '#ff7b00', '#5ED723']

const WalletCheckerPage = () => {

  const [walletInfos, setWalletData] = useState([])
  const [weeklyWalletInfos, setWeeklyWalletData] = useState([])
  
  const [searched, setSearched] = useState('')
  const [searchedChanged, setSearchedChanged] = useState(false)
  const [getPoints, setGetPoints] = useState(false)
  const [latestUpdate, setLatestUpdate] = useState()
  const toast = useToast()
  const [leaderboardInfo, setLeaderboardInfo] = useState([])
  const [individualWalletPoints, setIndividualWalletPoints] = useState(null);
  const [individualWalletRank, setIndividualWalletRank] = useState(null);
  
  const getRankings = (leaderboard) => {
    const sortedLeaderboard = [...leaderboard].sort((a, b) => b.points - a.points);
  
    let rank = 1;
    let prevPoints = null;
    const rankings = new Map();
  
    for (let entry of sortedLeaderboard) {
      if (prevPoints !== entry.points) {
        rank = rankings.size + 1;
      }
      rankings.set(entry.address.toLowerCase(), rank);
      prevPoints = entry.points;
    }
  
    return rankings;
  };
  
  useEffect(() => {
    const func = async () => {
      if(getPoints && searchedChanged) {
      axios.post(
        HYGRAPH_API_URL,
        {
          query: query,
          variables: { wallet: searched },
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
      
        if(response.data.data.points_phase_two.length == 0)
        toast('success', `If you put the eth address in the protocol it may take up to 24h to show up. Please check back later and contact us know if you have any issues.`, 15E3)
        else {
          console.log(response.data.data.points_phase_two[0].points)
          setWalletData(response.data.data.points_phase_two[0].points)
        }
        setGetPoints(false)
        setSearchedChanged(false)
        
      })
      .catch((error) => {
        console.error('Error:', error);
      });
      axios.post(
        HYGRAPH_API_URL,
        {
          query: GET_WEEKLY_POINTS_PER_USER,
          variables: { address: searched },
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
      
        if(response.data.data.weekly_points.length == 0)
        toast('success', `If you put the eth address in the protocol it may take up to 24h to show up. Please check back later and contact us know if you have any issues.`, 15E3)
        else {
          console.log(response.data.data.weekly_points[0].points)
          setWeeklyWalletData(response.data.data.weekly_points[0].points)
        }
        setGetPoints(false)
        setSearchedChanged(false)
        
      })
      .catch((error) => {
        console.error('Error:', error);
      });
     }
    };
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPoints,searchedChanged])


  useEffect(() => {
    const func = async () => {
      axios.post(
        HYGRAPH_API_URL,
        {
          query: queryLatestUpdate,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
      
        if(response.data.data.points_phase_two.length == 0)
        console.log(`couldn't get the latest upadte`)
        else {
          console.log(response.data.data.points_phase_two[0].created_at)
          setLatestUpdate(response.data.data.points_phase_two[0].created_at)
        }
        
      })
      .catch((error) => {
        console.error('Error:', error);
      });
     
    };
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.post(
          HYGRAPH_API_URL,
          { query: GET_WEEKLY_HIGHEST_POINTS },
          { headers }
        );
  
        if (response.data.data.weekly_points.length === 0) {
          toast('error', `Error fetching data.`, 15E3);
        } else {
          setLeaderboardInfo(response.data.data.weekly_points);
        }
      } catch (error) {
        console.error('Error:', error);
        toast('error', `Error fetching weekly points: ${error.message}`, 15E3);
      }
    };
  
    fetchLeaderboard();
  }, []);

  useEffect(() => {
    if (leaderboardInfo.length > 0 && searched) {
      const rankings = getRankings(leaderboardInfo);
      const normalizedSearched = searched.toLowerCase();
      const rank = rankings.get(normalizedSearched) || 'Not found';
      setIndividualWalletRank(rank);
  
      const individualWalletInfo = leaderboardInfo.find(info => info.address.toLowerCase() === normalizedSearched);
      if (individualWalletInfo) {
        setIndividualWalletPoints(individualWalletInfo.points);
      } else {
        setIndividualWalletPoints(0);
      }
    }
  }, [leaderboardInfo, searched]);

  const TimestampDisplay = () => {
    const date = new Date(latestUpdate);
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
  
    const formattedDate = `${date.getUTCDate()}.${
      monthNames[date.getUTCMonth()]
    }.${date.getUTCFullYear()} ${date.getUTCHours().toString().padStart(2, '0')}:00 UTC`;
  
    return formattedDate;
  };

  
  return (
    <div className='flex flex-grow flex-col justify-center text-center overflow-hidden' >
      <h1 className={`${isMobile() ? "text-md" : "text-3xl"}  text-white my-6 custome-font`}> 
      points check
      </h1>

      <h1 className={`${isMobile() ? "text-md" : "text-xl"} text-white my-6 mx-3 `}> 
      To collect points, comment your ETH address in the daily twitter post.  <br />
      We post once a day at 12:00 PM BST, allowing people to earn 30 points each time.   <br />
      For every 1,000 points, you will be allowed to mint one special block. <br />
      Collect as many points as possible. Points are updated every 24 hours.
      </h1>
      {/**
      <h1 className=" text-md text-white my-3 mx-3 ">
        There is a delay in data processing due to recent Twitter updates.<br /> We are working on it and it will be resolved soon.
      </h1>
      */}

      {/**
      { walletInfos > 0 ? 
      <h1 className="fm-terminal-font text-2xl text-white my-6">
      You have {walletInfos} { walletInfos == 1 ? `Point` : `Points`}.
      </h1> 
      : <h1 className="fm-terminal-font text-2xl text-white my-6" style={{visibility:"hidden"}}> Not Found.</h1>
      
      }
      */}

      { walletInfos > 0 ? 
      (
        <>
        <h1 className="fm-terminal-font text-2xl text-white my-6">
        You have {walletInfos} { walletInfos == 1 ? `Point` : `Points`}.
        </h1>
        {console.log("setLeaderboardInfo")}
        {console.log(leaderboardInfo)}
        <table className="max-w-screen-md table-fixed text-center border-collapse mx-auto px-12 border">
          <thead>
              <tr className="border-b">
                  <td className="table-cell text-left border-r p-2 font-griesotf border-t">
                      Total points collected
                  </td>
                  <td className="table-cell p-2 text-lg font-bold font-griesotf border-t">
                      {walletInfos}
                  </td>
              </tr>
          </thead>
          <tbody>
          {weeklyWalletInfos !== null && (
            <>
              <tr>
                <td className="table-cell text-left border-r p-2 font-griesotf">
                  Points collected this week
                </td>
                <td className="table-cell p-2 text-lg border-b whitespace-nowrap truncate">
                  {weeklyWalletInfos}
                </td>
              </tr>
              {/**
              <tr>
                <td className="table-cell text-left border-t border-r p-2 font-griesotf">
                  Weekly leaderboard position
                </td>
                <td className="table-cell p-2 text-lg border-b">
                  {individualWalletRank !== null ? individualWalletRank : 'Not ranked'}
                </td>
              </tr>
               */}
            </>
          )}
          {/**
              <tr>
                  <td className="table-cell text-left border-t border-r p-2 font-griesotf">
                    Weekly participants 
                  </td>
                  <td className="table-cell p-2 text-lg">
                    {leaderboardInfo.length}
                  </td>
              </tr>
          */}
          </tbody>
        </table>
        </>
      ):(
        <>
        <h1 className="fm-terminal-font text-2xl text-white my-6" style={{visibility:"hidden"}}> Not Found.</h1>
        </>
      )     
      }
      <div className='flex  flex-row my-6 justify-center text-center' >
        {colorID.map((color,i) => {
          return [
            <p key={color} className='p-4' style={{backgroundColor: color,margin:"1px"}}></p>
          ]}).flat()}
      </div>

      <div className='flex flex-col sm:flex-row md:flex-row lg:flex-row my-6 mx-6 '
      style={{marginInline : isMobile() ? "" : "20%"}} >
        
      <input className={`w-full text-black font-bold p-3 ${isMobile() ? "text-md" : "text-2xl"}`}
      placeholder='Enter Your Wallet Address (0x...)'
      onChange={ev => { if( ev.target.value.length == 42 && ev.target.value != searched) {setSearched(ev.target.value); setSearchedChanged(true) } else setWalletData([])}}/>

      <button className={`bg-gray-400 text-black font-bold p-3 ${isMobile() ? "text-md mt-3" : "text-2xl"}`}
      onClick={() => { setGetPoints(true) }}
      style={{width: isMobile() ? "" : "350px" }}>Check Allowlist</button>
      </div>

      <h1 className=" text-lg text-gray-400 my-1 mx-2"> 
      Data uploaded until tweet {TimestampDisplay()} 
      </h1>

    

    </div>
  )
}
export default WalletCheckerPage
