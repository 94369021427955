import React, { createContext, useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import firebaseApp from "./firebaseConfig";

export const AuthContext = createContext();
const firebaseAuth = getAuth(firebaseApp);

export const FirebaseAuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
