import { useToastDispatchContext } from '../context/ToastContext'

export const useToast = () => {
  const dispatchToast = useToastDispatchContext()

  const toast = (type, message, delay = 10000) => {
    const id = Math.random().toString(36).slice(2, 9)
    dispatchToast({ type: 'ADD_TOAST', toast: { type, message, id } })

    const deleteToast = () => dispatchToast({ type: 'DELETE_TOAST', id })

    setTimeout(deleteToast, delay)

    return deleteToast
  }

  return toast
}

export const useToastComposite = () => {
  const dispatchToast = useToastDispatchContext()

  const toastComposite = (type, message, children, delay = 10000) => {
    const id = Math.random().toString(36).slice(2, 9)
    dispatchToast({ type: 'ADD_TOAST', toast: { type, message, children, id } })

    const deleteToast = () => dispatchToast({ type: 'DELETE_TOAST', id })

    setTimeout(deleteToast, delay)

    return deleteToast
  }

  return toastComposite
}


