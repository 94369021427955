import {BrowserRouter as Router} from "react-router-dom";
import {QueryClientProvider} from "react-query";
import {queryClient} from "./query/queryClient";
import ToastContainer from "./components/Toast/ToastContainer";
import {ToastProvider} from "./context/ToastContext";
import { FirebaseAuthProvider } from "./auth/FirebaseAuthProvider";
import Main from "./Main";

function App() {
  return (
    <FirebaseAuthProvider>
        <QueryClientProvider client={queryClient}>
            <Router>
              <ToastProvider>
                <Main />
                <ToastContainer/>
              </ToastProvider>
            </Router>
        </QueryClientProvider>
    </FirebaseAuthProvider>
  );
}

export default App;
