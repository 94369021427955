import Page from "../designSystem/Page";
import Link from "../designSystem/Link";

function NotFoundPage() {
  return (
    <Page>
      <div className="space-y-8 pb-12">
        <h2 className="text-4xl font-bold">Not found</h2>
        <div className="space-y-1">
          <p>The page you&apos;re looking for doesn&apos;t exist.</p>
          <p>
            <Link to="/home">Return home?</Link>
          </p>
        </div>
      </div>
    </Page>
  );
}

export default NotFoundPage;
