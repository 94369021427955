// @ts-nocheck
/* eslint-env browser */
/* eslint-disable quotes, no-return-assign, object-curly-spacing */

const {location, localStorage} = window

const mob = {
  inited: false,
  isMobile: false
}

const redirect = () => setTimeout(() =>  window.location = window.location.href.split('?')[0], 100)

const detectMobile = () => window.matchMedia('(any-hover:none)').matches  // navigator.maxTouchPoints > 0

export const isMobile = () => {
  if (mob.inited) {
    return mob.isMobile
  }
  if (location.href.includes('?mob')) {
    localStorage.setItem('forcedMobileMode', 'mobile')
    redirect()
  } else if (location.href.includes('?desk')) {
    localStorage.setItem('forcedMobileMode', 'desktop')
    redirect()
  } else if (location.href.includes('?reset')) {
    localStorage.setItem('forcedMobileMode', '')
    redirect()
  }
  const forced = localStorage.getItem('forcedMobileMode')
  mob.forced = forced
  mob.isMobile = forced
    ? forced === 'mobile'
    : detectMobile()

  mob.inited = true
  document.body.setAttribute('mmode', mob.isMobile ? 'mobile' : 'desktop')
  return mob.isMobile
}

isMobile()
