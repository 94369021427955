/* eslint-disable no-return-assign, spaced-comment, no-unused-expressions, no-console, arrow-body-style,
   jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/prop-types, 
   no-nested-ternary, no-shadow
*/

import { useToastDispatchContext } from "../../context/ToastContext"

const Toast = ({ type, message, id, children }) => {
  const dispatch = useToastDispatchContext()
  return (
    <>
      {type === "success" && (
        <div className="_ToastSuccess ring-1 ring-green-450 bg-blue-451 p-4 m-3 z-20">
          <div className="flex">
            <div className="text-sm mb-0 font-medium text-green-450 uppercase">
              {message?.split('\n').map(line => <div key={line.slice(0, 6) + Math.random()}>{line}</div>)}
            </div>
            {children}
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  className="inline-flex p-1.5 text-green-450 hover:text-green-100 focus:outline-none"
                  onClick={() => dispatch({ type: "DELETE_TOAST", id })}
                  type="button"
                >
                  <span className="sr-only">Dismiss</span>
                  <svg
                    aria-hidden="true"
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "error" && (
          <div className="_ToastError ring-1 ring-red-450 bg-red-451 p-4 m-3 z-20">
            <div className="flex">
              <p className="text-sm mb-0 font-medium text-red-450 uppercase">{message}</p>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    className="inline-flex p-1.5 text-red-450 hover:text-red-100 focus:outline-none"
                    onClick={() => dispatch({ type: "DELETE_TOAST", id })}
                    type="button"
                  >
                    <span className="sr-only">Dismiss</span>
                    <svg
                      aria-hidden="true"
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        fillRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {children}
          </div>
      )}
    </>
  )
}

export default Toast