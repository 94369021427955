import React, { useContext } from "react";
import { getAuth, signOut } from "firebase/auth";
import firebaseApp from "./firebaseConfig";
import { AuthContext } from "./FirebaseAuthProvider";

const SignOut = () => {
  const { currentUser } = useContext(AuthContext);

  const handleSignOut = async () => {
    try {
      const firebaseAuth = getAuth(firebaseApp);
      await signOut(firebaseAuth);
      console.log("User signed out");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (!currentUser) {
    return <div></div>;
  }

  return (
    <button
      onClick={handleSignOut}
      className="_Header grid pr-2 bg-offblack text-white"
    >
      LOGOUT
    </button>
  );
};

export default SignOut;
