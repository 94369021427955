import React, {useEffect, useState} from 'react'
import {isMobile} from '../utils/isMobile'
import axios from 'axios'
import {useToast} from '../hooks'

const HYGRAPH_API_URL = process.env.REACT_APP_HASURA_ENDPOINT;
const authkey = process.env.REACT_APP_HASURA_SECRET_KEY;

const GET_WEEKLY_HIGHEST_POINTS = `
  query GetWeeklyLeaderboard($limit: Int, $offset: Int) {
    weekly_points(order_by: {points: desc}, limit: $limit, offset: $offset) {
      address
      points
    }
  }
`;
const headers = {
  'Content-Type': 'application/json',
  'x-hasura-admin-secret': authkey,
};

const colorID = ['#ffffff', '#ffcd00', '#df2020', '#4cb2e6', '#ff7b00', '#5ED723']

const WeeklyLeaderboardPage = () => {

  const [leaderboardInfo, setLeaderboardInfo] = useState([])
  const [offset, setOffset] = useState(0)

  // Helper function to calculate rankings
  const getRankings = (leaderboard) => {
    let rankings = [];
    let rank = 1;
    let prevPoints = null;

    for (let i = 0; i < leaderboard.length; i++) {
      const currentPoints = leaderboard[i].points;

      if (i !== 0 && currentPoints !== prevPoints) {
        rank += 1;
      }

      rankings.push(rank);
      prevPoints = currentPoints;
    }

    return rankings;
  };

  const handleMore = async () => {
    if(leaderboardInfo) {
      const newOffset = offset + 100;
      setOffset(newOffset)
      axios.post(
        HYGRAPH_API_URL,
        {
          query: GET_WEEKLY_HIGHEST_POINTS,
          variables: { limit: 100, offset: newOffset }
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if(response.data.data.weekly_points.length == 0)
          toast('error', `error fetching data.`, 15E3)
        else {
          setLeaderboardInfo([...leaderboardInfo, ...response.data.data.weekly_points])
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }
  };

  const truncateWalletAddress = (walletAddress) => {
    const start = walletAddress.slice(0, 3);
    const end = walletAddress.slice(-3);
    return `${start}...${end}`;
  };
   {/*
  useEffect(() => {
    const func = async () => {
      if(leaderboardInfo) {
      axios.post(
        HYGRAPH_API_URL,
        {
          query: GET_WEEKLY_HIGHEST_POINTS,
          variables: { limit: 100, offset: 0 }
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if(response.data.data.points_phase_two.length == 0)
          toast('error', `error fetching data.`, 15E3)
        else {
          setLeaderboardInfo(response.data.data.points_phase_two)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
     }
    };
    func();
  }, [leaderboardInfo]) */}
 
  useEffect(() => {
    const func = async () => {
      axios.post(
        HYGRAPH_API_URL,
        {
          query: GET_WEEKLY_HIGHEST_POINTS,
          variables: { limit: 100, offset: 0 }
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if(response.data.data.weekly_points.length == 0)
          toast('error', `error fetching data.`, 15E3)
        else {
          setLeaderboardInfo(response.data.data.weekly_points)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
     };
    func();
  }, [])
  const rankings = getRankings(leaderboardInfo);

  return( 
    <div className='flex flex-grow flex-col justify-center text-center overflow-hidden' >
    <h1 className=" text-3xl text-white my-6 custome-font"> 
    Weekly Points Leaderboard
    </h1>
    <div className='flex  flex-row my-6 justify-center text-center' >
        {colorID.map((color,i) => {
          return [
            <p key={color} className='p-4' style={{backgroundColor: color,margin:"1px"}}></p>
          ]}).flat()}
    </div>
    <table className="w-full table-fixed text-center border-collapse">
    <thead className="table-header-group">
      <tr>
        <th className="border-b p-2 font-griesotf">ranking</th>
        <th className="border-b p-2 font-griesotf">address</th>
        <th className="border-b p-2 font-griesotf">points</th>
      </tr>
    </thead>
    <tbody>
      {leaderboardInfo.map((point, index) => (
        <tr key={point.id} className="border-b">
          <td className="table-cell border-r p-2 text-lg font-bold font-griesotf">
            {rankings[index]}
          </td>
          <td className="table-cell border-r p-2 text-lg whitespace-nowrap truncate">
            {point.address}
          </td>
          <td className="table-cell border-b p-2 text-lg">
            {point.points}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
    <div className="flex flex-col items-center justify-center pt-4">
      <button
        className="bg-black text-white border border-white py-2 px-4 rounded hover:bg-white hover:text-black hover:border-transparent"
        onClick={handleMore}
      >
        load more
      </button>
      <div className='flex  flex-row my-6 justify-center text-center' >
          {colorID.map((color,i) => {
            return [
              <p key={color} className='p-4' style={{backgroundColor: color,margin:"1px"}}></p>
            ]}).flat()}
        </div>
    </div>
  </div>
 )
}
export default WeeklyLeaderboardPage
