/* eslint-disable object-curly-spacing, quotes, jsx-quotes, react/jsx-closing-bracket-location */

import React, {useEffect, useContext, useState} from 'react'
// import MintButton from "../mint/MintButton"
import {isMobile} from "../utils/isMobile"
// import {useCryptoartContract} from "../contracts/useCryptoartContract";
import {ExperiBar} from '../components/MxDia'
import {DropDownMenu} from '../components/DropDownMenu'
import { FaDiscord,FaTwitter } from "react-icons/fa"
import {ReactComponent as Opensea} from '../icons/Opensea.svg'
// import {useWallet} from '../ethWallet/useWallet'
import { AuthContext } from "../auth/FirebaseAuthProvider";
import SignOut from "../auth/SignOut";
import { ethers } from 'ethers'

const key = process.env.REACT_APP_OPENSEA_KEY;
const INFURA_ID = process.env.REACT_APP_INFURA_ID;
const big2int = big => parseInt(big._hex, 16)

const Header = () => {
  // const contractAdapter = useCryptoartContract()
  // const {hasLeaderboard} = contractAdapter
  // const wallet = useWallet()
  const { currentUser } = useContext(AuthContext);
  const [listings, setListings] = useState(0)
  const [totalSupply, setTotalSupply] = useState(0)
  const topMenuCl = `fm-link-button2 fm-terminal-font nowrap`
  const super1155UrlPath ='contracts/Super1155.json'
  // const {ethersProvider} = wallet

  // useEffect(() => {
    
  //   const func = async () => {
    
  //     const contractInfo = await fetch(super1155UrlPath)
  //     .then(response => response.json())
  //     .catch(err => console.error('Could not fetch contract file!', err, super1155UrlPath))
    
  //     const provider = new ethers.providers.InfuraProvider("mainnet",INFURA_ID)
  //     const superContract = new ethers.Contract("0xe418fa7A533f3E691025b6111278af83d9F5b709", contractInfo.abi, provider)
  //     const ts = await superContract.totalSupply()
  //     setTotalSupply(big2int(ts))
    
  //   }
  //   func();
   
  // }, [])

  
  // useEffect(() => {
   
  //   const options = {
  //     method: 'GET',
  //     headers: {accept: 'application/json', 'X-API-KEY': key}
  //   };

  //   fetch('https://api.opensea.io/v2/listings/collection/onchainpoints/all', options)
  //     .then(response => response.json())
  //     .then(response => {
       
  //       let sum =0 
  //       let offerer = []
  //       let exist = false
  //       for(let i =0 ; i<response.listings.length ; i ++) {
         
  //         exist = false
  //         for (let x = 0; x < offerer.length; x++) {
  //          if(offerer[x] == response.listings[i].protocol_data.parameters.offerer)
  //          exist = true
  //         }
  //         if(!exist) {
  //           offerer.push(response.listings[i].protocol_data.parameters.offerer)
  //           sum += parseInt(`${response.listings[i].protocol_data.parameters.offer[0]?.endAmount}`)
  //         }
  //       }
       
  //       setListings(sum)
  //     })
  //     .catch(err => console.error(err));
  
  // },[])

  return (
    <>
      <div
        className="_Header grid grid-cols-2 bg-offblack text-white"
        style={{borderBottom: "1px solid white"}}
      >
        {isMobile()
          ? (
            <>
            <div className="_Header mm-mobile flex flex-row items-center p-4">
              <DropDownMenu totalSupply={totalSupply} listings={listings} />
            </div>
            </>
            )
          : (
            <div className="_Header-in flex flex-row items-center p-4">
              <a className={topMenuCl} href="/">Home</a>
              {/* <a className={topMenuCl} href="/check-sp">checker</a>

              <a className={topMenuCl} href="/playtest">
                playtest
              </a> */}
              {/* 
              <a className={topMenuCl} href="/sbt-leaderboard">
                points leaderboard
              </a>
              <a className={topMenuCl} href="/weekly-leaderboard">
                weekly leaderboard
              </a>
              */}
              {/* <a className={topMenuCl} href="/info">info</a> */}

              {/* <a className={topMenuCl} href="/playtest-leaderboard">
                playtest leaderboard
              </a> */}
              {/*
              <a className={topMenuCl} href="https://offchain-leaderboard.onchainscores.xyz/"
              target="_blank" rel="noopener noreferrer">
                playtest leaderboard
              </a>*/}
            
              {/* <a className={topMenuCl} href="/info">info</a>
                <a className={topMenuCl} href="https://docs.google.com/forms/d/e/1FAIpQLSehTmwqEA41aQnzhaGGUBaLCKPpFax2eYtMAO0YAY23LBpXVA/viewform?usp=sf_link"
              target="_blank" rel="noopener noreferrer">
                Help
              </a> */}
              {currentUser ? (
                <SignOut />
              ):(
                <>
                </>
              )}
              {/* <a className={topMenuCl} href="https://opensea.io/collection/onchainpoints "
              target="_blank" rel="noopener noreferrer">
                <Opensea fill="white" className="fm-opensea"/>
              </a> */}
              <a className={topMenuCl} href="https://twitter.com/onchainscores"
              target="_blank" rel="noopener noreferrer">
              <FaTwitter className="fa fa-3x" size='22px' />
              </a>

              {/* <a className={topMenuCl} href="/">
              on sale : {listings}
              </a>

              <a className={topMenuCl} href="/">
              total supply : {totalSupply}
              </a> */}


              {/* <a className={topMenuCl} href="https://discord.com/invite/G7HcRz7dSN"
              target="_blank" rel="noopener noreferrer">
              <FaDiscord className="fa fa-3x" size='22px' />
              </a> */}
            </div>
            )}
      </div>

      {/* <div className="flex flex-row items-center p-4 w-full justify-center text-center text-black"
       style={{backgroundColor:"#ffcd00"}}>
        <a href="https://theburning.onchainscores.xyz/" 
         target="_blank" rel="noopener noreferrer">
        <p className={`fm-terminal-font text-center text-md`}>
        <u>THE BURNING HAS STARTED, CLICK HERE TO START</u> 
        </p></a>
      </div> */}
      <ExperiBar />
    </>
  )
}

export default Header