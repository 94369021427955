import { Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import {TosPage} from "./pages/TosPage";
import WalletCheckerPage from "./pages/WalletCheckerPage";
import NotFoundPage from "./pages/NotFoundPage";
// import HomePage from "./pages/HomePage";
import InfoPage from "./pages/InfoPage";
import SBLeaderboardPage from "./pages/SBLeaderboardPage";
import WeeklyLeaderboardPage from "./pages/WeeklyLeaderboardPage";


const Routes = () => 

    <Switch>
    
      <Route exact path="/">
        <WalletCheckerPage />
      </Route>
    
      <Route exact path="/tos">
        <TosPage />
      </Route>
      <Route exact path="/home">
        <LandingPage />
      </Route>
     {/* 
      <Route exact path="/playtest">
        <HomePage />
      </Route> */}

      <Route exact path="/check-sp">
        <WalletCheckerPage />
      </Route>

     {/* 
      <Route exact path="/sbt-leaderboard">
        <SBLeaderboardPage />
      </Route>
    */}
      <Route exact path="/weekly-leaderboard">
        <WeeklyLeaderboardPage />
      </Route>

      {/* <Route exact path="/playtest-leaderboard">
        <PlaytestLeaderboardPage />
      </Route>  */}

      <Route exact path="/info">
        <InfoPage />
      </Route>
      
      {/* <Route exact path="/howto">
        <HomePage />
      </Route> */}

      {/* <Route exact path="/walletChecker">
        <WalletCheckerPage />
      </Route> */}

   
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>

export default Routes;
