import React, {useEffect, useState} from 'react'
import {isMobile} from '../utils/isMobile'
import Joystick from "../img/Joystick.mp4";
import mining from "../img/mining.png";
import burning from "../img/burning.png";

import miningMobile from "../img/miningMobile.png";
import burningMobile from "../img/burningMobile.png";

const LandingPage = () => {

  const colorID = ['#ffffff', '#ffcd00', '#df2020', '#4cb2e6', '#ff7b00', '#5ED723']
 
  return(
 <>
  <div className='flex flex-grow flex-col justify-center text-left overflow-hidden gap-4'
  style={{marginRight:isMobile()? "5%" : "25%",marginLeft:isMobile()? "5%": "25%",marginTop:"3%",marginBottom:"3%"}} >

   
    <div className='flex  flex-row justify-center text-center mx-6' >
        {colorID.map((color,i) => {
        return [
          <p key={color} className='p-4' style={{backgroundColor: color,margin:"1px"}}></p>
        ]}).flat()}
    </div>    

    <p>
    Onchainscores is a 100% on-chain NFT game creating a peer to peer on-chain scoring system thanks to blockchain technology, smart contracts and dynamic NFTs.
    </p>

    <video  height="300" controls className='my-3' autoPlay={true}>
      <source src={Joystick} type="video/mp4" autoPlay={true}/>
    </video>

    <p>
    It is a disruptive protocol, delivering innovation in its entirety, with a unique approach where the smart contract is able to identify and react accordingly to the NFT properties in a trustless manner. 
    </p>
   
    <p>
      {`Why? To create a peer-to-peer score system that doesn't rely on a third party to evaluate the performance of an asset, and much more… `}
    </p>

    <h1 className=" text-xl text-white my-6 "> 
    <b>What are POINTS and the POINTS protocol?</b>
    </h1>

    <p>
    To create a successful on-chain NFT mint, the protocol introduced various phases to establish a balanced and inclusive system. This accessible and meritocratic concept aims to provide opportunities for participation regardless of capital power. It operates as a resource-based ecosystem, enabling farmers to extract resources by completing tasks through a web2 application and transforming these into web3 tokens for consumption within the game.
    </p>

    {isMobile() ? 
    <img src={miningMobile} className='w-full my-6' /> :
    <img src={mining} className='w-full my-6' />
    }

    <p>
     POINTS can be farmed by completing Twitter tasks, open to anyone. This Proof of Work farming mechanism resembles the role of miners in the Bitcoin ecosystem. POINT farmers can utilise the Twitter protocol system by completing tasks and accumulating POINTS.
    </p>
    {isMobile() ? 
    <img src={burningMobile} className='w-full my-6' /> :
    <img src={burning} className='w-full my-6' />
    }
    <p>POINTS will be burned to mint BLOCKS for the SCORES game, when it launches</p>
    <p>1000 POINTS will allow to mint 1 BLOCK, POINTS will be tradable NFTs. </p>
    <p>BLOCKS are the fundamental, unique digital assets that players can own and use within the game. </p>
    
  </div>
 </>
 )
}
export default LandingPage
